import React, { Fragment } from 'react';
import { graphql, Link } from 'gatsby';
import {
  Chip, Container, Grid,
} from '@material-ui/core';
import Img from 'gatsby-image';
import SubLayout from './subLayout';
import AllServices from '../components/allServices';
import SEO from '../components/seo';
import { IconRight } from '../components/featureCard';
import DlaczegoWarto from '../components/dlaczegoWarto';

const { kebabCase } = require('../utils/slugs');

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const tagMeta = data.tag.edges && data.tag.edges[0];
  const tagFrontmatter = tagMeta && tagMeta.node && tagMeta.node.frontmatter;
  const {
    header, desc, metaTitle, tagLine, bgImg,
  } = tagFrontmatter || {};
  return (
    <SubLayout
      title={(
        <>
          <span>
            {tag}
          </span>
          {' '}
          -
          {' '}
          Blog iqcode
        </>
              )}
      subtitle={tagLine || `Temat ${tag} na blogu iqcode Software House`}
      img={bgImg || '/img/biznes-kontakt.jpg'}
    >
      <SEO
        title={metaTitle || `${tag} - blog iqcode Software House`}
        description={desc || 'Blog technologiczny iqcode. Tutaj dzielimy się doświadczeniem przy realizacji projektów programistycznych.'}
      />
      <Container>
        <Grid spacing={3} container>
          <Grid item xs={12} sm={12} md={7} className="blog-feed">
            {data.posts.edges.map((post) => (
              <div key={post.node.frontmatter.title}>
                <h2><Link to={post.node.frontmatter.slug}>{post.node.frontmatter.title}</Link></h2>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '20px',
                  }}
                >
                  <div>
                    <img
                      alt={post.node.frontmatter.author}
                      src="/img/pkuffel.jpg"
                      style={{
                        width: '50px',
                        borderRadius: '100px',
                      }}
                    />
                  </div>
                  <div style={{ marginLeft: '10px' }}>
                    <address
                      className="author"
                      style={{
                        fontStyle: 'normal',
                        marginBottom: '8px',
                      }}
                    >
                      {post.node.frontmatter.author}
                    </address>
                    {post.node.frontmatter.date}
                    {' '}
                    <span className="post-tags-separator">
                      |
                    </span>
                    {' '}
                    <div className="post-tags">
                      {post.node.frontmatter.tags.map(
                        (tag) => (
                          <Fragment key={tag}>
                            <Chip
                              component={Link}
                              to={`/blog/tag/${kebabCase(tag)}/`}
                              label={tag}
                              size="small"
                              style={{
                                marginBottom:
                                                                    '5px',
                                marginLeft:
                                                                    '5px',
                                verticalAlign:
                                                                    '0%',
                              }}
                            />
                            {' '}
                          </Fragment>
                        ),
                      )}
                    </div>
                  </div>
                </div>
                <Img
                  style={{
                    margin: '15px 0px 30px 0px',
                    borderRadius: '12px',
                    boxShadow: '0px 5px 15px #00000045',
                  }}
                  fluid={
                                        post.node.frontmatter.featuredImage
                                          .childImageSharp.fluid
                                    }
                  alt={post.node.frontmatter.featuredImageAlt}
                />
                <p>{post.node.frontmatter.lead}</p>
                <p>
                  <Link to={post.node.frontmatter.slug}>
                    Czytaj dalej
                    {' '}
                    <IconRight />
                  </Link>
                </p>
              </div>
            ))}
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            {tagMeta
            && (
            <article className="tag-info">
              <h2>{header}</h2>
              <div dangerouslySetInnerHTML={{ __html: tagMeta.node.html }} />
            </article>
            )}
          </Grid>

        </Grid>
      </Container>
      <div className="promo overlay-holder z-depth-3">
        <div className="diagonal-overlay-light" />
        <div className="rel">
          <h3>
            Porozmawiajmy o Twoim
            {' '}
            <span>Projekcie</span>
            !
          </h3>
          <h4>
            Wycenę
            {' '}
            <i>fixed price</i>
            {' '}
            prześlemy w ciągu 72h
          </h4>
          <a className="btn" href="mailto:kontakt@iqcode.pl">
            Napisz do nas!
          </a>
        </div>
      </div>
      <DlaczegoWarto />
      <AllServices />
    </SubLayout>
  );
};

export default Tags;

export const pageQuery = graphql`
    query($tag: String) {
        posts: allMarkdownRemark(
            limit: 2000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { tags: { in: [$tag] } } }
        ) {
            totalCount
            edges {
                node {
                    frontmatter {
                        date(formatString: "DD-MM-YYYY")
                        slug
                        title
                        author
                        metaTitle
                        desc
                        lead
                        tags
                        featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 800, quality: 85) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        featuredImageAlt
                    }
                }
            }
        }
        tag: allMarkdownRemark(
          filter: {frontmatter: { tagName: {in: [$tag] }}}
        ) {
          edges {
            node {
              html
              frontmatter {
                header
                desc
                metaTitle
                tagName
                bgImg
                tagLine
              }
            }
          }
        }
    }
`;
